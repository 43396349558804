import Profile from "../components/Profile";
import SectionDivider from "../components/SectionDivider";
import zickleTrioPic from "../images/zicklet-trio.png";
import clarkstachioPic from "../images/profiles/cukestachio.png";
import stargelnazPic from "../images/profiles/cukeitall.png";
import artistByronPic from "../images/profiles/articuke.png";

const profiles = [
  {
    name: "stargelnaz",
    alias: "lorezickle",
    rank: "3rd Warrant Gherkin",
    mintNum: "7 of 1 - IDKYB",
    variety: "Dill",
    speciality: "Making Up Stuff",
    caption: 'His favorite TV show is "Let\'s Make a Dill"',
    picture: stargelnazPic,
  },
  {
    name: "ArtistByron",
    alias: "popzickle",
    rank: "Master Cuke",
    mintNum: "1 of 1 - twice 🔥",
    variety: "experimentDILL Hybrid",
    speciality: "Ink Slinger",
    caption: "All Your Dill Are Belong To Us.",
    picture: artistByronPic,
  },
  {
    name: "clarkstachio",
    alias: "devzickle",
    rank: "Master Cuke",
    mintNum: "1 of 1M - 999,999 🔥",
    variety: "Super Sour Gherkin",
    speciality: "Interwebs Dev",
    caption: "I don't want a pickle, just want to ride on my motorzickle.",
    picture: clarkstachioPic,
  },
];

const TeamSection = () => {
  return (
    <section id="team" className="team-section bg-team">
      <h2 className="text-center mb-5">Team Zickle</h2>
      <article className="intro-section mb-5 p-3">
        <div className="container">
          <div className="row justify-content-start align-items-center">
            <div className="d-none d-md-block col-md-3 text-center text-md-start mb-3 mb-md-0">
              <img
                src={zickleTrioPic}
                alt="Zicklet Trio"
                className="img-fluid"
              />
            </div>
            <div className="col-sm-12 col-md col-lg-6 text-center text-md-start">
              <h3>Meet the Cukes Behind the Zickles!</h3>
              <p className="lead">
                Every cucumber patch has a couple of sprouts that like gherkin
                around and making a big mess of things ... and these are ours!
              </p>
              <p className="lead">
                Born from the pen of ArtistByron in early 2021, the sometimes
                cute, but always ill-tempered and undead pickles became the
                trio's obsession. Thanks for joining us on this half-brined
                journey!
              </p>
            </div>
          </div>
        </div>
      </article>
      <article className="team-zickle">
        <h3 className="text-center mb-4">Classified</h3>
        <div className="container">
          <div className="row justify-content-center g-0">
            {profiles.map((member) => {
              return (
                <div
                  key={member.name}
                  className="col-lg-5 col-xl-4 mb-4 mb-xl-0"
                >
                  <Profile person={member} className="mx-auto" />
                </div>
              );
            })}
          </div>
        </div>
      </article>
      <SectionDivider colorClass="text-blood" align="bottom" className="mt-2" />
    </section>
  );
};

export default TeamSection;
