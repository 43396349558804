import handleScroll from "../../utils/handle-scroll";
import whitePaper from "../../documents/my_zickle_army_whitepaper_2021-11-03.pdf";

const Footer = () => {
  return (
    <section className="site-footer bg-blood text-light">
      <nav className="social-nav text-center mb-4 py-2">
        <div className="container-fluid d-md-flex justify-content-center align-items-baseline">
          <h3 className="my-0 py-0">Be Social We won't bite!</h3>
          <ul className="list-inline ms-4">
            <li className="list-inline-item">
              <a
                aria-current="page"
                href="https://twitter.com/MyZickleArmy"
                target="_blank"
                rel="noreferrer"
              >
                <i className="bi-twitter"></i>
              </a>
            </li>
            <li className="list-inline-item">
              <a
                aria-current="page"
                href="https://discord.gg/rA3UMEkpqc"
                target="_blank"
                rel="noreferrer"
              >
                <i className="bi-discord"></i>
              </a>
            </li>
          </ul>
        </div>
      </nav>
      <div className="container-fluid">
        <div className="row justify-content-center mb-4">
          <div className="col-md-5 col-lg-4 pe-5">
            <h4 className="brand">My Zickle Army</h4>
            <p className="dim">
              Command an army of undead pickles! Start building your army now
              and be ready for the My Zickle Army invasion.
            </p>
          </div>
          <div className="col-md-3 col-lg-2">
            <h4>Site Nav</h4>
            <ul className="site-nav list-unstyled">
              <li>
                <a href="#home" onClick={handleScroll}>
                  Home
                </a>
              </li>
              <li>
                <a href="#magor" onClick={handleScroll}>
                  Magor 22:1
                </a>
              </li>

              <li>
                <a href="#events" onClick={handleScroll}>
                  Events
                </a>
              </li>

              <li>
                <a href="#team" onClick={handleScroll}>
                  Team
                </a>
              </li>

              <li>
                <a href={whitePaper} target="_blank" rel="noreferrer">
                  Whitepaper
                </a>
              </li>
            </ul>
          </div>
          <div className="col-md-3 col-lg-2">
            <h4>Buy Zickles</h4>
            <ul className="buy-nav list-unstyled">
              <li>
                <a
                  href="https://neftyblocks.com/c/myzicklearmy"
                  target="_blank"
                  rel="noreferrer"
                >
                  NeftyBlocks
                </a>
              </li>
              <li>
                <a
                  href="https://wax.atomichub.io/market?collection_name=myzicklearmy&order=desc&sort=created&symbol=WAX"
                  target="_blank"
                  rel="noreferrer"
                >
                  AtomicHub
                </a>
              </li>
            </ul>
          </div>
          <div className="mt-1 mt-lg-0 mx-auto mx-lg-0 text-start text-md-center text-lg-start col-lg-3">
            <div className="row justify-content-center">
              <div className="col-md-4 col-lg-12">
                <h4>WAX Donations</h4>
                <p className="dim">
                  Send to <strong>zickle.army</strong>
                </p>
              </div>
              <div className="col-md-4 col-lg-12">
                <h4>Contact Us</h4>
                <p className="dim">
                  <a
                    href="mailto:info@myzicklearmy.io"
                    target="_blank"
                    rel="noreferrer"
                  >
                    info@myzicklearmy.io
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright text-center">
        <div className="container-fluid">
          <p className="dim">&copy;2021 Byron Rempel &amp; Team Zickle</p>
        </div>
      </div>
    </section>
  );
};

export default Footer;
