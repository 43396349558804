const Post = ({ title, date, children }) => {
  const postDate = new Date(date);

  return (
    <li className="event py-4">
      <div className="row">
        <div className="col-md-2">
          <div className="event-date mx-auto mx-md-0 my-3 my-md-0">
            {postDate.getDate()}
            <br />
            {postDate.toLocaleString("default", { month: "short" })}
          </div>
        </div>
        <div className="col-md col-lg-9">
          <h4 className="event-title text-center text-sm-start mb-3">
            {title}
          </h4>
          <div className="event-detail clearfix">{children}</div>
        </div>
      </div>
    </li>
  );
};

export default Post;
