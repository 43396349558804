import handleScroll from "../../utils/handle-scroll";
import whitePaper from "../../documents/my_zickle_army_whitepaper_2021-11-03.pdf";

const Navigation = () => {
  return (
    <nav
      id="app-navbar"
      className="navbar navbar-expand-lg navbar-dark bg-blood app-navbar w-100"
    >
      <div className="container-fluid">
        <a className="navbar-brand" href="#home" onClick={handleScroll}>
          My Zickle Army
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ms-lg-3 main-nav">
            <li className="nav-item">
              <a className="nav-link" href="#game" onClick={handleScroll}>
                Game
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#magor" onClick={handleScroll}>
                Magor 22:1
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#events" onClick={handleScroll}>
                Events
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#team" onClick={handleScroll}>
                Team
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href={whitePaper}
                target="_blank"
                rel="noreferrer"
              >
                Whitepaper
              </a>
            </li>
          </ul>
          <ul className="navbar-nav social-nav ms-auto">
            <li className="nav-item">
              <a
                className="nav-link"
                aria-current="page"
                href="https://twitter.com/MyZickleArmy"
                target="_blank"
                rel="noreferrer"
              >
                <i className="bi-twitter"></i>
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                aria-current="page"
                href="https://discord.gg/rA3UMEkpqc"
                target="_blank"
                rel="noreferrer"
              >
                <i className="bi-discord"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
