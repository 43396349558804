import { ReactComponent as BottomDivider } from "../images/bottom-divider.svg";
import { ReactComponent as TopDivider } from "../images/top-divider.svg";

const SectionDivider = ({ colorClass, align, className }) => {
  console.log(className);
  const svgClasses = `divider ${align} ${colorClass}`;
  const divider =
    align === "bottom" ? (
      <BottomDivider className={`${svgClasses}`} />
    ) : (
      <TopDivider className={`${svgClasses}`} />
    );

  return <div className={`${className}`}>{divider}</div>;
};

export default SectionDivider;
