import PostListing from "../components/Blog/PostListing";
import Post from "../components/Blog/Post";
import SectionDivider from "../components/SectionDivider";
import zombieFarmer from "../images/zombie-farmer.png";
// import queenMaggortPot from "../images/queen-maggort-pot.gif";
// import teenyZickle from "../images/og-zickles/teeny-zickle.png";
// import leggyZickle from "../images/og-zickles/leggy-mczickle.png";
// import zickleUps from "../images/og-zickles/zickle-ups.png";
// import deadPinhead from "../images/events/dead-pinhead-henchmen.png";
// import neftyRaffle from "../images/nefty-raffle.gif";
// import zicklePack from "../images/basic-zickle-pack.png";
// import discordLogo from "../images/events/discord_logo.png";
// import magorSkullflake1 from "../images/events/magor-snowflake-1.png";
// import magorSkullflake2 from "../images/events/magor-snowflake-2.png";
// import blizardPass from "../images/events/blizzard-pass.png";
import genesisPack from "../images/events/genesis-pack.png";
import largeBlizzardSack from "../images/events/blizzard-large-sack.png";
import smallBlizzardSack from "../images/events/blizzard-small-sack.png";
import xmasSubscription from "../images/events/xmas-subscription.png";
import miningPass from "../images/mining-pass/mining-pass.png";

const EventSection = () => {
  return (
    <section id="events" className="event-section bg-events">
      <h2 className="text-center mb-4">News and Events</h2>
      <article className="section-intro bg-secondary">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-md-7 col-lg-6 col-xl-5 text-center text-md-start">
              <h3 className="section-title my-3">
                Extra, Extra! Read the "Dead" Lines.
              </h3>
              <p className="lead">
                Latest My Zickle Army news and event information to die for.
              </p>
            </div>
            <div className="col-md col-lg-4 text-center">
              <img
                src={zombieFarmer}
                className="img-fluid section-image"
                alt="Zombie Farmer"
              />
            </div>
          </div>
        </div>
      </article>
      <article className="events my-5 pt-3">
        <div className="container-lg">
          <PostListing>
            <Post
              title="Pre-season for Weeds of Magor, Season 2: The Thawing..."
              date="3/10/2022"
            >
              <p>
                Summers are brief on the icy desert of Magor 22:1. And with the
                thawing comes... krubhs (grubs of magor)!
              </p>
              <p>
                For a limited time, when you mine on Magor 22:1 you can uncover
                "krubh shards" (wear gloves!). Collect all four pieces of any
                rarity and blend them for an exclusive "pre-season" NFT.
              </p>
              <p>
                These shards are limited to the pre-season and will not be
                re-released (not ever). Their blends are special black-and-white
                NFTs and those black-and-white blends will not be re-released
                (not ever, never). The blends will be useable in the My Zickle
                Army game (stay tuned for news!).
              </p>
              <p>You'll need the new Season 2 pass to mine.</p>
              <div style={{ width: "300px" }}>
                <img
                  src={miningPass}
                  alt="Season 2 mining pass"
                  className="img-fluid"
                />
                <p className="text-center mt-1">
                  <a
                    className="btn btn-blood"
                    href="https://neftyblocks.com/c/myzicklearmy/drops/107595"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Get yours here!
                  </a>
                </p>
              </div>
            </Post>
            <Post title="12 Days of Zickles!" date="12/5/2021">
              <p>
                Yeah Zickmas isn't Christmas. The 12 days start on December 14th
                with daily drops for each day. You can{" "}
                <a
                  href="https://neftyblocks.com/c/myzicklearmy/drops/79445"
                  target="_blank"
                  rel="noreferrer"
                  className="link-dark"
                >
                  buy a "subscription"
                </a>{" "}
                for all 12 days, or you can check in each day to buy one (with
                gradually increasing prices each day, bwahaaa haa evil laugh :)
              </p>
              <img
                src={xmasSubscription}
                alt="12 Days of Zickles Subscription"
                className="img-fluid"
                style={{ width: "450px" }}
              />
              <p>
                <a
                  href="https://neftyblocks.com/c/myzicklearmy/drops/79445"
                  target="_blank"
                  rel="noreferrer"
                  className="link-dark"
                >
                  Purchase a subscription
                </a>{" "}
                and hold in your wallet for a daily airdrop.
              </p>
            </Post>
            <Post title="Magor Blizzard NFTs!" date="12/3/2021">
              <p>
                Henchmen on Magor are risking their fingers to gather
                skullflakes, snowballs, and snowbeasts to sell to those who have
                the wax! You can continue to mine the skullflakes on Magor 22:1
                and blend them (just make sure you have a gardening pass). Or,
                for the month of December, you can purchase a{" "}
                <strong>Big Sack</strong> or a <strong>Small Sack</strong> of
                Magor Blizzard NFTs.
              </p>
              <div className="d-sm-flex align-items-start text-center text-sm-start">
                <img
                  src={largeBlizzardSack}
                  alt="Large Blizzard Sack"
                  className="event-img img-fluid d-block me-sm-4 mx-auto mx-sm-0 mb-2 bg-magorpurple rounded"
                />
                <img
                  src={smallBlizzardSack}
                  alt="Small Blizzard Sack"
                  className="event-img img-fluid d-block mx-auto mx-sm-0 mb-2 bg-magorpurple rounded"
                />
              </div>
              <p className="mt-3">
                See the details on{" "}
                <a
                  href="https://neftyblocks.com/c/myzicklearmy/drops/78598%2B78600"
                  target="_blank"
                  rel="noreferrer"
                  className="link-dark"
                >
                  NeftyBlocks
                </a>
                . Supplies are limited.
              </p>
            </Post>
            <Post title="GENESIS PACKS Coming Very Soon!" date="11/22/2021">
              <p>
                GENESIS PACKS are happening, everyone! In just five days! <br />
                <a
                  href="https://neftyblocks.com/c/myzicklearmy/drops/74258"
                  target="_blank"
                  rel="noreferrer"
                  className="link-dark"
                >
                  Dropping on NeftyBlocks
                </a>
              </p>
              <div className="text-center text-sm-start">
                <img
                  src={genesisPack}
                  alt="Genesis Pack"
                  className="event-img mb-2"
                />
              </div>
              <p>Some details:</p>
              <ul>
                <li>
                  21 new zickles in five rarities (common 50%, uncommon 30%,
                  rare 15%, super rare 4.5%, legendary 0.5%).
                </li>
                <li>
                  Each pack contains 3 (probably angry) zickles and a surprise
                  4th NFT for some lucky pack openers.
                </li>
                <li>
                  125 packs will be dropped and 50 packs held for the vouchers
                  and other promotions.
                </li>
                <li>
                  Cost is 10 WAX and you can buy 5 packs every 15 minutes.
                </li>
                <li>Packs can be opened 30 minutes after the sale starts.</li>
                <li>
                  Those of you with VOUCHERS will be able to blend those into
                  packs once the pack sales begin.
                </li>
              </ul>
              <p>
                These cards will be useable in at least THREE ways: 1)
                Collectors love them 2) The My Zickle Game will use them and
                their special abilities and 3) Most of them will be useable as
                we build our "grow a cucumber" operation and then start building
                the "zickleinators" so that you can build your own zickles! Be
                sure to bookmark{" "}
                <a
                  href="https://myzicklearmy.io"
                  target="_blank"
                  rel="noreferrer"
                  className="link-dark"
                >
                  myzicklearmy.io
                </a>{" "}
                for these and other announcements.
              </p>
            </Post>
            {/* <Post
              title="SEVERE WEATHER WARNING for Magor 22:1"
              date="11/19/2021"
            >
              <p className="clear">
                The <strong>Magor Skullflakes</strong> are beautiful AND deadly.{" "}
                <a
                  href="https://neftyblocks.com/c/myzicklearmy/drops/72272"
                  className="link-dark"
                  target="_blank"
                  rel="noreferrer"
                >
                  Get your Blizzard Pass
                </a>{" "}
                and come mine in Alien Worlds for your chance to get the
                exclusive NFTs for this special event.
              </p>
              <div className="d-sm-flex text-center text-sm-start">
                <img
                  src={blizardPass}
                  alt="Basic Zickle Pack"
                  className="event-img me-4 img-fluid"
                />
                <img
                  src={magorSkullflake1}
                  alt="Magor Skullflake 1"
                  className="event-img img-fluid"
                />
                <img
                  src={magorSkullflake2}
                  alt="Magor Skullflake 2"
                  className="event-img img-fluid"
                />
              </div>
              <p className="mt-4">
                <em>
                  NOTE: If you have a Season 1 Gardening Pass, those will also
                  work!
                </em>
              </p>
            </Post> */}
            {/* <Post
              title="Discord Invite Contest! Zombie Cake!"
              date="11/17/2021"
            >
              <p>
                Invite your friends to join our{" "}
                <a
                  className="link-dark"
                  href="https://discord.gg/rA3UMEkpqc"
                  target="_blank"
                  rel="noreferrer"
                >
                  discord server
                </a>
                . Once they are active, the Zombie Cake channel opens and prizes
                will be given!
              </p>
              <a
                href="https://discord.gg/rA3UMEkpqc"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={discordLogo}
                  alt="Discord Logo"
                  className="event-img"
                  style={{ width: "5rem" }}
                />
              </a>
            </Post>
            <Post title="Zickle Packs Are Coming Soon!" date="11/16/2021">
              <img
                src={zicklePack}
                alt="Basic Zickle Pack"
                className="event-img mb-2 me-3 rounded float-start"
              />
              <p>
                You know you need more zickles! And you will certainly need them
                to play the upcoming games on My Zickle Army! We will release
                the “Genesis” version of the Basic Zickle Packs on{" "}
                <a
                  className="link-dark"
                  href="https://neftyblocks.com/c/myzicklearmy"
                  target="_blank"
                  rel="noreferrer"
                >
                  NeftyBlocks
                </a>{" "}
                at 12pm UTC on Saturday, November 27th!{" "}
              </p>
              <p>
                Each pack will contain five zickle game cards, each one with the
                special “genesis” attribute. This will be the only time the
                attribute will EVER appear on a game card, regardless of whether
                it gets reprinted in the future! The basic packs will come with
                at least one RARE or better card.
              </p>
            </Post>
            <Post
              title="Season 1: The Weeds of Magor Awards Update"
              date="11/14/2021"
            >
              <p>
                Good day/evening everyone! As you know we are concluding Season
                1 and will be airdropping rewards based on wallet contents
                starting soon on <strong>Nov 15th 11am UTC</strong>, give or
                take a few minutes. That’s just 12 hours from now! We will post
                an announcement once the reward drop has been completed.
              </p>
              <p>Thank you for a fun season and looking forward to the next!</p>
            </Post>
            <Post title="Nefty Raiser Raffle" date="11/09/2021">
              <img
                src={neftyRaffle}
                alt="Nefty Raffle"
                className="event-img mb-3 me-3 rounded float-start"
              />
              <p>
                Why the raffle? We need to raise NEFTY so that MyZickleArmy can
                reach level 1 on Neftybloks. This will allow us to do more super
                blendy cool things to give you guys more and more options for
                what to do with your Magor NFTs, Zickles, and other Byronverse
                items.
              </p>
              <p>
                Raffle tickets will cost 4 WAX for one; 33 $NEFTY for three; 50
                $NEFTY for five.
              </p>
              <p>
                Winning Raffle ticket will receive an exclusive 1/1 NFT of
                Byron's (which was chosen by the community for the raffle, and
                generously offered by Byron). 2-10th places will receive a Basic
                Zickle Pack (when released). 11-20th places will receive
                cucumber parts.
              </p>
              <p>
                BUT WAIT, there's more! After the drawing for the winners, your
                used raffle tickets can be spent to buy Zickle Packs, Cucumber
                Parts, and other goodies.
              </p>
              <p>
                TICKETS{" "}
                <a
                  href="https://neftyblocks.com/c/myzicklearmy/drops/67032+67033+67046"
                  target="_blank"
                  rel="noreferrer"
                  className="link-dark"
                >
                  {" "}
                  on sale now
                </a>{" "}
                and will be available to buy for 1 week.
              </p>
              <p>
                DID I MENTION ZICKLE PACKS? Stay tuned for more info on that!{" "}
              </p>
            </Post>
            <Post
              title="Zombie Henchmen coming to Magor 22:1"
              date="11/06/2021"
            >
              <img
                src={deadPinhead}
                alt="Queen Maggort Pot"
                className="event-img mb-3 me-3 rounded float-start"
              />
              <p>
                What comes after Season 1? Season 1-and-a-half, of course! While
                Season 1 will run through next week, the Zombie Henchmen will
                return to Magor 22:1.
              </p>
              <p>
                These pieces of art from @ByrontheArtist are collectibles, and
                will (most probably) play a role in the upcoming My Zickle Army
                Game (details to be announced).
              </p>
              <p>Interested in snagging a MINT #1?</p>
              <p>
                {" "}
                Check the announcements channel on our{" "}
                <a
                  href="https://discord.gg/rA3UMEkpqc"
                  target="_blank"
                  rel="noreferrer"
                  className="link-dark"
                >
                  Discord
                </a>{" "}
                for auction details.
              </p>
            </Post>
            <Post
              title="OG Zickles - Get them while you can!"
              date="11/01/2021"
            >
              <p>
                Over the next few days, we are going to re-release the black and
                white OG Zickles. You may want to grab these while you can. The
                vault awaits the OG Zickles. After that, they will only be
                released as game pieces! These OGs will have a lot of potential.
                Just sayin’.
              </p>
              <p className="text-center text-md-start">First Zickles up ...</p>
              <div className="d-flex flex-wrap justify-content-center justify-content-md-start">
                <div
                  className="card text-center resource-item me-1 mb-1"
                  style={{ width: "200px" }}
                >
                  <img
                    src={leggyZickle}
                    alt="The OG Leggy McZickle"
                    className="bg-dark card-img-top"
                  />
                  <div className="card-body">
                    <p className="card-text">Leggy McZickle</p>
                    <a
                      className="btn btn-blood"
                      role="button"
                      href="https://neftyblocks.com/c/myzicklearmy/drops/62476"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Purchase
                    </a>
                  </div>
                </div>
                <div
                  className="card text-center resource-item me-1 mb-1"
                  style={{ width: "200px" }}
                >
                  <img
                    src={teenyZickle}
                    alt="The OG Teeny Zickle"
                    className="bg-dark card-img-top"
                  />
                  <div className="card-body">
                    <p className="card-text">Teeny Zickle</p>
                    <a
                      className="btn btn btn-blood"
                      role="button"
                      href="https://neftyblocks.com/c/myzicklearmy/drops/62478"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Purchase
                    </a>
                  </div>
                </div>
                <div
                  className="card text-center resource-item me-1 mb-1"
                  style={{ width: "200px" }}
                >
                  <img
                    src={zickleUps}
                    alt="The OG zickleUps"
                    className="bg-dark card-img-top"
                  />
                  <div className="card-body">
                    <p className="card-text">zickleUps</p>
                    <a
                      className="btn btn-blood"
                      role="button"
                      href="https://neftyblocks.com/c/myzicklearmy/drops/62479"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Purchase
                    </a>
                  </div>
                </div>
              </div>
              <p className="mt-3">
                Only available for one week or until sold out. Then it’s off to
                the vault for them!
              </p>
            </Post>
            <Post title="Halloween Special" date="10/31/2021">
              <img
                src={queenMaggortPot}
                alt="Queen Maggort Pot"
                className="img-thumbnail event-img mb-3 me-3 float-start"
              />
              <p>
                Behold the Gold-Bordered Queen Maggort Pot! This in-game item
                will have a very limited release, as only the ones minted this
                weekend will ever exist. After that, It'll be a silver border or
                regular pot that you'll be collecting for!
              </p>
              <p>
                You must complete the instructions below by Sunday at 24:59 UTC.
              </p>
              <button
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseInstructions"
                aria-expanded="false"
                aria-controls="collapseInstructions"
                className="btn btn-blood d-block my-3"
              >
                Read Instructions
              </button>
              <div
                id="collapseInstructions"
                className="collapse instructions clear rounded"
              >
                <h5 className="text-center my-3">Instructions</h5>
                <ol>
                  <li>
                    Collect all eight "pot shards" by Mining on Magor 22:1 (with
                    gardening pass), buying on the{" "}
                    <a
                      href="https://wax.atomichub.io/market?collection_name=myzicklearmy&data:text.rarity=shard&match=pot%20shard&order=desc&schema_name=weedsofmagor&sort=created&symbol=WAX"
                      target="_blank"
                      rel="noreferrer"
                      className="link-dark"
                    >
                      secondary market
                    </a>
                    , or trading in the #👽-magor-22-1 channel on our discord
                    server.
                  </li>
                  <li>
                    Blend the pot shards into a "Basic Magor Planter"{" "}
                    <a
                      href="https://neftyblocks.com/c/myzicklearmy/blends/336766"
                      target="_blank"
                      rel="noreferrer"
                      className="link-dark"
                    >
                      here
                    </a>{" "}
                    or buy on the{" "}
                    <a
                      href="https://wax.atomichub.io/market?collection_name=myzicklearmy&data:number.card_id=20&order=desc&schema_name=weedsofmagor&sort=created&symbol=WAX"
                      target="_blank"
                      rel="noreferrer"
                      className="link-dark"
                    >
                      secondary market
                    </a>
                    .
                  </li>
                  <li>
                    Mine a "Stikum" from Magor 22:1 or buy one{" "}
                    <a
                      href="https://neftyblocks.com/c/petsofclarks/drops/54229"
                      target="_blank"
                      rel="noreferrer"
                      className="link-dark"
                    >
                      here
                    </a>{" "}
                    or on the{" "}
                    <a
                      href="https://wax.atomichub.io/market?collection_name=petsofclarks&data:text.card_id=GLUE-1&order=desc&sort=created&symbol=WAX"
                      target="_blank"
                      rel="noreferrer"
                      className="link-dark"
                    >
                      secondary market
                    </a>
                    .
                  </li>
                  <li>
                    Mine any ONE Magorfly (there are seven!) from Magor 22:1 or
                    buy on{" "}
                    <a
                      href="https://wax.atomichub.io/market?collection_name=monicarempel&match=magorfly&order=asc&sort=price&symbol=WAX)"
                      target="_blank"
                      rel="noreferrer"
                      className="link-dark"
                    >
                      secondary market
                    </a>
                    .
                  </li>
                  <li>
                    <p>
                      Because we don't yet have Nefty Level 1 status (but
                      hopefully will soon), you will need to send the three
                      pieces to this address: <strong>myzicklename</strong>. In
                      return you will get a token to exchange for the final NFT
                      after the promotion is over!
                    </p>
                    <p className="note">
                      Best way to do this is to set up a trade. In Atomic Hub,
                      login and choose "Trading" at the top, then find "New
                      Trade Offer". Select the three pieces. Then choose
                      "Recipient Name" and enter myzicklename. And choose ONE of
                      the tokens. I will then accept the trade and you have your
                      token!
                    </p>
                  </li>
                </ol>
              </div>
            </Post> */}
          </PostListing>
        </div>
      </article>
      <SectionDivider colorClass="text-team" align="bottom" />
    </section>
  );
};

export default EventSection;
