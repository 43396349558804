import React from "react";
import SectionDivider from "../components/SectionDivider";
import vibingZickle from "../images/vibing-zickle.gif";
import magorPlanter from "../images/basic-magor-planter.png";
import alienTool from "../images/alien-tool.png";
import knokerly from "../images/zickle-miner.png";
import battleZickle from "../images/battle-zickle.png";
import hordeAttack from "../images/horde-attack.png";
import zicklet from "../images/zicklet.png";

const GameSection = () => {
  return (
    <section id="game" className="game-section">
      <h2 className="text-center text-light">Game of Zickles</h2>
      <article className="section-intro py-4 my-4">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-md col-lg-5 col-xl-4 d-flex justify-content-center align-items-center flex-column order-1 order-md-0">
              <div className="image-container text-center rounded-circle">
                <img src={vibingZickle} alt="Vibing Zickle" />
              </div>
              <p className="pt-2 lead-caption">
                Zickle (n) /ˈpɪk(ə)l/ a zombified pickle
              </p>
            </div>
            <div className="col-md col-lg-5 text-center text-md-start">
              <h3>Do you have what it takes to command an army of zickles?</h3>
              <p className="lead pt-3">
                Start building your army now and be ready for the My Zickle Army
                invasion.
              </p>
            </div>
          </div>
        </div>
      </article>
      <article className="build-army">
        <div className="container">
          <h3 className="text-center my-5 py-2">
            <span className="marker-highlight rounded">
              There are 3 ways to build your Zickle Army
            </span>
          </h3>
          <div className="row justify-content-center align-items-center mb-4">
            <div className="col-md col-lg-5 col-xl-4 text-light text-center order-1 order-md-0">
              <h4 className="mb-3">Grow your own</h4>
              <p>
                Start with a seed and some soil. Grow a gherkin, then a
                cucumber, then a pickle, then decant it and transform it into a
                zombie pickle that you can train, upgrade, and name.
              </p>
            </div>
            <div className="col-md col-lg-5 col-xl-4 text-center order-0 order-md-1">
              <img
                src={magorPlanter}
                alt="Basic Magor Planter"
                className="img-fluid rounded-circle border border-2 border-dark mb-2"
              />
            </div>
          </div>
          <div className="row justify-content-center align-items-center mb-4">
            <div className="col-md col-lg-5 col-xl-4 text-light order-2 text-center">
              <h4 className="mb-3">Build your own</h4>
              <p>
                Scattered every where are the parts needed to build the ultimate
                zickle soldier, or a horde of zickle grunts. Collect NFTs from
                various mini-games, related collections, and even some outside
                collections. Blend together, cross your fingers and hope for the
                best!
              </p>
            </div>
            <div className="col-md col-lg-5 col-xl-4 text-center">
              <img
                src={alienTool}
                alt="Alien Tool"
                className="rounded-circle bg-dark border border-2 border-dark mb-2"
              />
            </div>
          </div>
          <div className="row justify-content-center align-items-center">
            <div className="col-md col-lg-5 col-xl-4 text-light text-center order-1 order-md-0">
              <h4 className="mb-3">Buy an army</h4>
              <p>
                The secondary market has the zickles you need, if you’ve got the
                WAX.
              </p>
            </div>
            <div className="col-md col-lg-5 col-xl-4 text-center order-0 order-md-1">
              <img src={knokerly} alt="Knockerly" className="img-fluid mb-2" />
            </div>
          </div>
          <hr className="border" />
        </div>
      </article>
      <article>
        <h3 className="text-center my-5">
          <span className="marker-highlight rounded">
            What can you do with your zickles?
          </span>
        </h3>
        <div className="container">
          <div className="row">
            <div className="col-lg">
              <div className="card nft-uses text-center">
                <img
                  src={zicklet}
                  alt="Magor Planter"
                  className="mx-auto rounded-circle bg-dark card-img-top border border-2 border-dark"
                />
                <div className="card-body">
                  <h4 className="text-center">Collect them all!</h4>
                  <p className="px-md-5 px-lg-0">
                    Zickles come in different rarities, with different features.
                    Some zickles can only be made through blending, while others
                    are exclusively found in other games.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md">
              <div className="card nft-uses text-center">
                <img
                  src={battleZickle}
                  alt="Magor Planter"
                  className="card-img-top bg-dark rounded-circle mx-auto border border-2 border-dark"
                />
                <div className="card-body">
                  <h4 className="text-center">Zickle battles!</h4>
                  <p>
                    Send out your champion to battle one and one with other
                    zickles (ZvZ). Or you can play the more casual daily
                    mini-game. You can win gear, leaderboard points, and even
                    crypto.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md">
              <div className="card nft-uses text-center">
                <img
                  src={hordeAttack}
                  alt="Magor Planter"
                  className="mx-auto rounded-circle card-img-top bg-dark border border-2 border-dark"
                />
                <div className="card-body">
                  <h4 className="text-center">Unleash the Horde!</h4>
                  <p>
                    Seasonal events will dictate your strategy as you send your
                    army to face others. Other zickles? Sure. Zombie cats?
                    Maybe. Cute otters? Well... probably not.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
      <SectionDivider
        colorClass="text-magorpurple"
        align="bottom"
        className="mt-2"
      />
    </section>
  );
};

export default GameSection;
