import SectionDivider from "../components/SectionDivider";
import handleScroll from "../utils/handle-scroll";
import zickleArmy from "../images/zickle-army.png";

const Landing = () => {
  return (
    <section id="landing" className="landing-section">
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col-md-5 text-center text-md-start">
            <h1>
              Grow An Army
              <br />
              Of Angry <span className="text-pickle">Pickles</span>
            </h1>
            <p className="lead py-2">
              In an apocalyptic game of zombie pickles, dumb luck, and a splash
              of vinegar.
            </p>
            <div>
              <a
                href="#magor"
                className="btn btn-blood me-1"
                onClick={handleScroll}
              >
                Mine on Magor
              </a>
              <a
                href="https://discord.gg/rA3UMEkpqc"
                target="_blank"
                rel="noreferrer"
                className="btn btn-magorblue"
              >
                Join Horde Discord!
              </a>
            </div>
          </div>
          <div className="col-md-5 col-xl-4 text-center mt-4 mt-md-0 d-none d-md-block">
            <img src={zickleArmy} alt="My Zickle Army" className="img-fluid" />
          </div>
        </div>
        <h4 className="text-center mt-5">
          Check out the{" "}
          <a
            href="https://wax.atomichub.io/tools/book/myzicklearmy"
            target="_blank"
            rel="noreferrer"
            className="link-magorblue"
          >
            MZA Collection Book
          </a>
        </h4>
      </div>
      <SectionDivider colorClass="text-army" align="bottom" className="mt-4" />
    </section>
  );
};

export default Landing;
