import React from "react";
import Navigation from "./components/UI/Navigation";
import Landing from "./pages/Landing";
import MagorSection from "./pages/MagorSection";
import GameSection from "./pages/GameSection";
import EventSection from "./pages/EventSection";
import SectionDivider from "./components/SectionDivider";
import TeamSection from "./pages/TeamSection";
import Footer from "./components/UI/Footer";

function App() {
  return (
    <React.Fragment>
      <header id="app-header" className="fixed-top">
        <Navigation />
        <SectionDivider colorClass="text-blood" align="top" classes="w-100" />
      </header>
      <SectionDivider
        colorClass="text-transparent"
        align="top"
        classes="w-100"
      />
      <Landing />
      <GameSection />
      <MagorSection />
      <EventSection />
      <TeamSection />
      <Footer />
    </React.Fragment>
  );
}

export default App;
