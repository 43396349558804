const Profile = ({ person, className }) => {
  return (
    <div className={`card profile border border-2 border-dark ${className}`}>
      <div className="row bg-dark g-0 p-2 align-items-center justify-content-center card-image">
        <div className="col-5 align-self-end">
          <img src={person.picture} alt="profile" className="img-fluid" />
        </div>
        <div className="col-5">
          <div className="profile-name bg-magorblue text-light rounded-pill text-center py-3">
            {person.alias} <br />
          </div>
        </div>
      </div>
      <div className="card-body bg-events">
        <div className="row">
          <div className="col-4">Name</div>
          <div className="col">{person.name}</div>
        </div>
        <div className="row">
          <div className="col-4">Rank</div>
          <div className="col">{person.rank}</div>
        </div>
        <div className="row">
          <div className="col-4">Mint #</div>
          <div className="col">{person.mintNum}</div>
        </div>
        <div className="row">
          <div className="col-4">Variety</div>
          <div className="col">{person.variety}</div>
        </div>
        <div className="row">
          <div className="col-4">Specialty</div>
          <div className="col">{person.speciality}</div>
        </div>
        {/* <h4 className="text-center mt-3">Classified</h4> */}
        {/* <p>
          Often heard singing “I don’t want a pickle, I just want to ride on my
          motorzickle”.
        </p> */}
      </div>
    </div>
  );
};

export default Profile;
