import miningPass from "../images/mining-pass/mining-pass.png";
import barryKrubh from "../images/magor-rewards/barry-krubh.png";
import discoKrubh from "../images/magor-rewards/disco-krubh.png";
import mauriceKrubh from "../images/magor-rewards/maurice-krubh.png";
import robinKrubh from "../images/magor-rewards/robin-krubh.png";
import magorGardens from "../images/magorgardens.png";
import zombieShovel from "../images/zombie-shovel.png";
import SectionDivider from "../components/SectionDivider";

const MagorSection = () => {
  return (
    <section id="magor" className="magor-section">
      <h2 className="text-center text-magorblue">Area Magor 22:1</h2>
      <article className="mining-pass mx-5 mt-4">
        <div className="container coupon rounded bg-magorpurple p-4">
          <div className="row justify-content-center align-items-center text-center">
            <div className="col-md-6 col-lg-5 ">
              <img
                src={miningPass}
                alt="Season 2 Mining Pass"
                className="img-fluid"
                style={{ width: "78%" }}
              />
            </div>
            <div className="col-md-6 col-lg-7 px-4 px-sm-5 px-md-3 d-flex flex-column justify-content-start align-items-start text-md-start">
              <h3 className="my-3 text-center align-self-center">
                Season 2: The Thawing ...
              </h3>
              <p>
                More legs than your normal grubs, the krubhs also have bitey
                fangs. Tasty as a mid-afternoon snack, some folks use them to
                improve their soil when growing ... things. The krubhs appear
                during <em>The Thawing</em>, the short Magor 22:1 "summer".
              </p>
              <p>
                For a limited time, when you mine on Magor 22:1 you can uncover
                "krubh shards" (wear gloves!). Collect all four pieces of any
                rarity and blend them for an exclusive "pre-season" NFT.
              </p>
              <p className="caption">
                <i className="fas fa-cut"></i> Cut here and hold in wallet
              </p>
              <div className="mt-auto align-self-center">
                <a
                  href="https://neftyblocks.com/c/myzicklearmy/drops/107595"
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-blood me-2"
                >
                  Get Gardening Pass
                </a>
                <a
                  href="https://play.alienworlds.io"
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-magorblue"
                >
                  Mine Now!
                </a>
              </div>
            </div>
          </div>
        </div>
      </article>
      <article className="my-5 resources-info text-center text-light">
        <h3 className="p-2">Mine shards on Magor 22:1 and blend the krubhs:</h3>
        <div className="container">
          <div className="row justify-content-center align-items-center g-2 mb-4">
            <div className="col-md-4 col-lg-3">
              <div className="card resource-item mx-auto">
                <div className="card-body text-center">
                  <img
                    src={barryKrubh}
                    className="img-fluid"
                    alt="Barry Krubh"
                  />
                  <p className="card-text pt-3">
                    <a
                      className="btn btn-sm btn-magorblue"
                      href="https://neftyblocks.com/c/myzicklearmy/blends/453888"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Blend
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-lg-3">
              <div className="card resource-item mx-auto">
                <div className="card-body">
                  <img
                    src={discoKrubh}
                    className="img-fluid"
                    alt="Disco Krubh"
                  />
                  <p className="card-text pt-3">
                    <a
                      className="btn btn-sm btn-magorblue"
                      href="https://neftyblocks.com/c/myzicklearmy/blends/454191"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Blend
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="w-100 d-lg-none"></div>
            <div className="col-md-4 col-lg-3">
              <div className="card resource-item mx-auto">
                <div className="card-body">
                  <img
                    src={mauriceKrubh}
                    className="img-fluid"
                    alt="Maurice Krubh"
                  />
                  <p className="card-text pt-3">
                    <a
                      className="btn btn-sm btn-magorblue"
                      href="https://neftyblocks.com/c/myzicklearmy/blends/453893"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Blend
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-lg-3">
              <div className="card resource-item mx-auto">
                <div className="card-body">
                  <img
                    src={robinKrubh}
                    className="img-fluid"
                    alt="Robin Krubh"
                  />
                  <p className="card-text pt-3">
                    <a
                      className="btn btn-sm btn-magorblue"
                      href="https://neftyblocks.com/c/myzicklearmy/blends/453897"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Blend
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <a
            className="btn btn-blood"
            href="https://wax.atomichub.io/market?collection_name=myzicklearmy&order=asc&schema_name=myzickles&sort=price&symbol=WAX"
            target="_blank"
            rel="noreferrer"
          >
            Buy Krubhs on Secondary
          </a>
        </div>
      </article>
      <article className="mining-rules my-5 py-4 text-dark bg-zickleskin">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-md-8 col-lg-7 col-xl-5">
              <h3 className="mb-4 text-center text-md-start">
                How to mine Magor Gardens NFTs?
              </h3>
              <ul>
                <li>Mine on Magor 22:1</li>
                <li>Hold a gardening pass (any variety) in your wallet.</li>
                <li>
                  Receive 1 entry into drawing for each mine &gt; 0.01 TLM
                </li>
                <li>
                  Receive bonus entry into drawing for each mine &gt; 0.025 TLM
                </li>
                <li>NFTs dropped to winning wallets each hour</li>
              </ul>
            </div>
            <div className="col-md-4 col-lg-3 text-center">
              <img
                src={zombieShovel}
                alt="Magor 22:1"
                className="img-fluid d-none d-md-block"
              />
            </div>
          </div>
        </div>
      </article>
      <article className="nft-uses text-light p-3">
        <div className="container text-center text-md-start">
          <h3 className="text-center mb-4 mb-md-5">
            Collect! Earn Rewards! Blend! Buy/SellTrade!
          </h3>
          <div className="row justify-content-start g-md-5">
            <div className="col-md col-lg-4 col-xl-3 mb-4 mb-md-none">
              <img
                src={magorGardens}
                alt="Magor Gardens"
                className="img-fluid  rounded border border-5 border-dark"
              />
            </div>
            <div className="col-md">
              <div className="card resource-item mb-4">
                <div className="card-body">
                  <h4 className="card-title">
                    Collect Magor Gardens NFTs and earn rewards!
                  </h4>
                  <p className="card-text">
                    At the end of the season, limited edition reward cards will
                    go out to each user who collects various combinations of
                    NFTs.
                  </p>
                </div>
              </div>
              <div className="card resource-item my-4">
                <div className="card-body">
                  <h4 className="card-title">Power up your zickles!</h4>
                  <p className="card-text">
                    <em>Magor Gardens</em> series is fully compatible with the{" "}
                    <em>My Zickle Army</em> game. Some are power ups; some are
                    machine parts; some are mysterious magical goodness waiting
                    to be discovered.
                  </p>
                </div>
              </div>
              <div className="card resource-item mt-4">
                <div className="card-body">
                  <h4 className="card-title">Put them in the blender!</h4>
                  <p className="card-text">
                    Blend <em>Magor Gardens</em> NFTs for a chance to earn rare
                    items!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
      <SectionDivider
        colorClass="text-events"
        align="bottom"
        className="mt-3"
      />
    </section>
  );
};

export default MagorSection;
