const handleScroll = (e) => {
  e.preventDefault();

  const [, bookmark] = e.target.href.split("#");
  const target = document.getElementById(bookmark);
  const header = document.getElementById("app-header");
  const targetPosition = target.getBoundingClientRect().top;
  const offset = parseInt(header.getBoundingClientRect().bottom);

  window.scrollTo({
    top: targetPosition + window.scrollY - offset,
    behavior: "smooth",
  });
};

export default handleScroll;
